<template>
    <card-template>
        <template slot="titleCard">{{ this.$alarms_locale._list }}</template>
        <div class="table-responsive">
            <config-alarms-table
                :enableCreateBtn="true"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
            >
            </config-alarms-table>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>
import ConfigAlarmsTable from './ConfigAlarmsTable'
export default {
    name: 'ConfigAlarmsList',
    created() {
        this.getConfigAlarms()
    },
    components:{
        ConfigAlarmsTable
    },
    data() {
        return {
            idTable: 'configAlarmsTable',
            items: [],
            customFilters: [

            ],
            customFields: [

            ],
            columns: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "item",
                    "label": this.$alarms_locale.config_alarms.table_columns.item
                },
                {
                    "field": "evaluate",
                    "label": this.$alarms_locale.config_alarms.table_columns.evaluate_config_alarm
                },
                {
                    "field": "description",
                    "label": this.$alarms_locale.config_alarms.table_columns.description
                },
                {
                    "field": "created_at",
                    "label": this.$alarms_locale.config_alarms.table_columns.created_at
                },
                {
                    "field": "options",
                    "label": this.$alarms_locale.config_alarms.table_columns.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        getConfigAlarms() {
            axios.get('/api-admin/alarms/getConfigAlarms').then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            window.location.href = `/alarms/config/${item.item_options_url}`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$alarms_locale.modals.delete_alarm_config}  `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/alarms/config/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
                this.getConfigAlarms()
            })
        },
    }

}
</script>

