import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import NotificationsStore from '/app/Modules/Notifications/Resources/assets/js/store'
import ZoneStore from '/app/Modules/DevicesBundle/Resources/assets/components/zones/store'
import AnalyticsStore from '/app/resources/js/components/analytics/store'
import DeviceStore from '/app/Modules/DevicesBundle/Resources/assets/components/devices/store'
Vue.use(Vuex)


export default new Vuex.Store({
	modules:{
        analytics: AnalyticsStore,
        devices: DeviceStore,
		notifications: NotificationsStore,
        zones: ZoneStore,
	},
	plugins: [
		createPersistedState({
			storage: window.sessionStorage,
		}),
	],
})
