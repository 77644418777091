<template>
    <div>
        <div class="card-device-grid row ">
            <div class=" card-device col-4"  v-for="(device,index) in devices" :key="index">
                <div class="card">
                    <div class="card-header p-1">
                        <div class="w-100 d-flex">
                        <span class="col-7 ">
                        {{ device.name }}
                        </span>
                            <div class="col-5 text-right">
                                <span class="mr-1" v-if="device.time_device">{{device.time_device}}</span>
                                <span class="mr-2"><i
                                    :class="'m-1 fa-solid fa-battery-'+device.battery_icon"></i>{{ device.signals!=null&&device.signals.battery_level != null ? device.signals.battery_level + '%' : '-' }}</span>
                                <span><i
                                    :class="`mr-1 fa fa-circle ${device.is_online?'text-success':'text-danger'}`"></i></span>
                            </div>
                        </div>
                        <div class="w-100 d-flex">
                            <div class="text-right col-12">
                                <div>
                                    <button  @click="editItem(device)" class="btn btn-xs btn-warning"><i class="fa fa-edit"></i></button>
                                    <button  @click="actions(device)" class="btn btn-xs btn-primary"><i class="fa fa-cog"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0">
                        <table class="devices-grid-table list-unstyled table table-borderless">
                            <tbody>
                            <tr>
                                <td>
                                    {{ $devices_locale.columns.zone }}:
                                </td>
                                <td>
                                    {{ device.zone }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ $t('Installation') }}:
                                </td>
                                <td>
                                    {{ device.installation }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ $devices_locale.columns.description }}:
                                </td>
                                <td>
                                    {{ device.description }}
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $devices_locale.columns.last_lifebit }}:</td>
                                <td> {{ device.last_lifebit_parse }}</td>
                            </tr>

                            </tbody>
                        </table>
                        <span :href="`#signals-accordion-${device.id}`"
                              :class=" 'signal-accordion d-block bg-white card-header '"
                              data-toggle="collapse"
                              role="button" :aria-expanded="true "
                              :aria-controls="`#signals-accordion-${device.id}`">
                        {{ $t('signals') }}:
                    </span>

                        <div :class="'p-2 collapse '"
                             :id="`signals-accordion-${device.id}`">
                            <div class="signals-tr" v-for="(signal,index) in device.signals">
                                <span>- {{ index }}:</span>
                                <span>{{ signal }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DeviceGrid",
    created() {
    },
    computed: {
        setSignalsDevices(device) {
            return []
        },
        isOpen() {
            // return this.openId === this.de
        }
    },
    data() {
        return {
            device_signals: [],
            showOptions: []
        }
    },
    props: {
        devices: {required: true}
    },
    methods: {
        editItem(item) {
            window.open(`/devices/${item.id}/edit`, '_')
        },
        actions(item) {
            window.location = `/devices/${item.id}/actions`
        }
    }
}
</script>

<style lang="scss">
.signal-accordion {
    padding-left: 3% !important;
}

.card-device {
    cursor: pointer;

    &:hover {
        .card{
           border: 1px solid black;
        }

    }
}

.devices-grid-table {
    margin-top: 2%;
    margin-bottom: 0;

    tr {
        &:first-child {

        }

        td {
            padding: 0 3%;

        }
    }

    .signals-tr {
        td {
            padding-left: 5%;
        }
    }
}
</style>
