<template>
    <div>
        <inno-core-datatable
            :enableCreateBtn="false"
            :enableEditBtn="false"
            :enableDeleteBtn="false"
            idTable="EvHistory"
            :columnsData="columns"
            :rowItems="ev_history"
            :btnConfig="false"
        />
    </div>

</template>
<script>
import {mapGetters} from 'vuex'

export default {
    name: 'EvHistory',
    computed: {
        ...mapGetters('devices', ['ev_history'])
    },
    data() {
        return {
            columns: [
                {
                    field: 'init_at',
                    label: this.$t('ev_init_at')
                },
                {
                    field: 'finish_at',
                    label: this.$t('ev_stop_at')
                },
                {
                    field: 'duration',
                    label: this.$t('ev_seconds')
                }
            ]
        }
    }
}
</script>
