export const ev_history = state => state.ev_history
export const ev_pin_open = state => state.ev_pin_open
export const ev_device_open = state => state.ev_device_open

export const ev_enabled = (state) => {
    return state.ev_pin_open && state.ev_device_open
    // return state.ev_device_open
}

export const has_ev = state => state.current_device ? state.current_device.ev_address : null
