<template>
    <div>
        <apex-chart height="300px" :options="options_1" :series="series"/>
        <apex-chart height="250px" :options="options_2" :series="series"/>
    </div>
</template>
<script>
import ApexChart from 'vue-apexcharts'

export default {
    name: 'HistorySignalChart',
    props: {
        title: {
            type: String
        },
        id: {
            required: true
        },
        yaxis_scale: {},
        height: {},
        series: {
            type: Array,
            required: true,

        },
        show_guide: {
            type: Boolean,
            default: false
        },
        guide_value: {
            type: Number,
        },
        guide_label: {
            type: String,
            default: ''
        }
    },
    components: {
        ApexChart
    },
    created() {
        this.setSelector()
    },
    data() {
        return {
            options_1: {
                title: {
                    text: this.title,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                chart: {
                    id: "chart2",
                    type: "area",
                    height: 230,
                    foreColor: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],//"#ccc",
                    toolbar: {
                        autoSelected: "pan",
                        show: false
                    }
                },
                colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],//["#00BAEC"],
                stroke: {
                    show: true,
                    curve: 'straight'
                    // curve: ['smooth','straight','stepline']
                },
                grid: {
                    borderColor: "#555",
                    clipMarkers: true,
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: true
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },

                tooltip: {
                    theme: "dark"
                },
                xaxis: {
                    type: 'datetime',
                    tickAmount: 6,
                    labels: {
                        format: 'dd/MM HH:mm',
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                    }
                },
                yaxis: {
                    min: 0
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            offsetY: 0,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 'bold',
                                colors: ['#000']
                            },
                        }
                    }
                },
            },
            options_2: {
                chart: {
                    id: "chart1",
                    height: 100,
                    type: "bar",
                    foreColor: "#ccc",
                    brush: {
                        target: "chart2",
                        enabled: true
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: "#fff",
                            opacity: 0.4
                        },
                        xaxis: {}
                    }
                },
                colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],//["#00BAEC"],
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                stroke: {
                    width: 2
                },
                grid: {
                    borderColor: "#444"
                },

                xaxis: {
                    type: 'datetime',
                    tickAmount: 6,
                    labels: {
                        format: 'dd/MM HH:mm',
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                    }
                },
                yaxis: {},
                tooltip: {
                    enabled: true
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            offsetY: 0,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 'bold',
                                colors: ['#000']
                            },
                        }
                    }
                }

            }
        }
    },
    methods: {
        setSelector() {
            const date_min = this.series[0].data[0][0]
            const date_max = this.series[0].data[5][0]
            this.options_2.chart.selection.xaxis = {
                min: new Date(date_min).getTime(),
                max: new Date(date_max).getTime()
            }
        }
    }
}
</script>
