<template>
    <card-template>
        <template slot="titleCard">{{ this.$devices_locale.nav }}</template>
        <div class="table-responsive">
            <inno-core-datatable
                :enableCreateBtn="this.$helpers.hasPermission('devices.device.create')"
                :enableEditBtn="this.$helpers.hasPermission('devices.device.update')"
                :enableDeleteBtn="this.$helpers.hasPermission('devices.device.delete')"
                :idTable="idTable"
                :columnsData="columns"
                :rowItems="setItems"
                :customFields="customFields"
                :btnConfig="true"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
                @show="showItem"
                @config="actions"
                :enableInstallationFilter="true"
                @filterInstallation="filterInstallation"
            />
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>
    </card-template>
</template>

<script>

export default {
    name: 'DeviceTable',
    props: {
        devices: {required: true}
    },
    data() {
        return {
            idTable: 'devicesTable',
            items: [],
            customFields: [],
            columns: [
                {
                    field: 'id',
                    label: '#',
                    display: 'min_tabletP',
                },
                {
                    field: 'name',
                    label: this.$devices_locale.columns.name,
                },
                {
                    field: 'code',
                    label: this.$devices_locale.columns.code,
                },
                {
                    field: 'device_type_name',
                    label: this.$devices_locale.columns.type
                },
                {
                    field: 'device_parent_name',
                    label: this.$devices_locale.columns.device_depend
                },
                {
                    field: 'installation',
                    label: this.$devices_locale.columns.installation
                },
                {
                    field: 'zone',
                    label: this.$devices_locale.columns.zone
                },
                {
                    field: 'options',
                    label: this.$devices_locale.columns.options
                }
            ]
        }
    },
    computed: {
        setItems(){
            this.items = this.devices
            return this.items
        }
    },
    methods: {

        editItem(item) {
            window.location = `/devices/${item.id}/edit`
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$devices_locale.modals.delete_device} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/devices/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getItems()
            })
        },
        newItem() {
            window.location = '/devices/create'
        },
        showItem(item) {
            window.location = `/devices/${item.id}/show`
        },
        filterInstallation(installation){
            this.$emit('filterInstallation',installation)
        },
        actions(item) {
            window.location = `/devices/${item.id}/actions`
        }
    }

}
</script>
