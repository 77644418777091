export const setCurrentDevice = (state,payload) => {
    state.current_device = payload
}

export const setEvPinStatus = (state,payload) => {
    state.ev_pin_open = parseInt(payload) === 1
}

export const setEvDeviceStatus = (state,payload) => {
    state.ev_device_open = parseInt(payload) === 1
}

export const setEvHistory = (state,payload) => {
    state.ev_history = payload
}

export const resetData = (state) => {
    state.current_device = null
    state.ev_pin_open = false
    state.ev_device_open = false
    state.ev_history = []
}
