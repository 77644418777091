<template>
		<div>
				<label v-if="show_label">{{ this.$t('Installation') }}</label>
				<v-select
						@input="add"
						v-model="selected"
						:multiple="multiple"
						:options="items"
						label="name"
						code="id">

				</v-select>
		</div>
</template>
<script>

import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"

export default {
		name: 'InstallationsCombo',
		props: {
				'current': {required: false},
				'multiple': {default: false},
				'show_label': {default: true}
		},
		components: {
				vSelect
		},
		created() {
				this.setData()
				if (this.current) {
						this.selected = this.multiple ? [...this.current] : {...this.current}
				}
		},
		watch: {
				current: function () {
						if (this.current) {
								this.selected = this.multiple ? [...this.current] : {...this.current}
						}
				},
				selected: function () {
						if (this.selected === null) this.add()
				}
		},
		data() {
				return {
						selected: null,
						items: []
				}
		},
		methods: {
				setData() {
						axios.get('/api-admin/installations/combo').then(response => {
								this.items = response
						})
				},
				add() {
						this.$emit('add', this.selected)
				}
		}
}
</script>
