<template>
    <card-template>
        <template slot="titleCard">{{ this.$alarms_locale.alarm_types_section.list_title }}</template>
        <div class="table-responsive">
            <alarm-types-table
                :enableCreateBtn="true"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
            >
            </alarm-types-table>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>
        <alarm-types-modal
            :id="idModal"
            :current="current"
            :refresh_data_fn="getAlarmTypes"
        />
    </card-template>
</template>
<script>
import AlarmTypesTable from './AlarmTypesTable'
import AlarmTypesModal from './AlarmTypesModal'

export default {
    name: 'AlarmTypesList',
    created() {
        this.getAlarmTypes()
    },
    components:{
        AlarmTypesTable,AlarmTypesModal
    },
    data() {
        return {
            idTable: 'alarmTypesTable',
            items: [],
            idModal: 'AlarmTypeModal',
            current:null,
            customFilters: [
            ],
            customFields: [

            ],
            columns: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "name",
                    "label": this.$alarms_locale.table_columns.name
                },
                {
                    "field": "created_at",
                    "label": this.$alarms_locale.table_columns.created_at
                },
                {
                    "field": "options",
                    "label": this.$alarms_locale.table_columns.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        getAlarmTypes() {
            axios.get('/api-admin/alarms/getAlarmTypes').then(response => {
                const rows = response.data
                this.items = rows
            })
        },
        editItem(item) {
            this.current = item
            $(`#${this.idModal}`).modal('show')
            // window.open(`/alarms/types/${item.id}`,'_blank')
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$alarms_locale.modals.delete_alarm_type} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/alarms/types/${item.id}`).then(response => {
                this.$toast.success(this.$t('toast.delete_success'))
                this.getAlarmTypes()
            })
        },
        newItem() {
            this.current = null
            $(`#${this.idModal}`).modal('show')
            // window.location = '/alarms/types/create'
        }

    }

}
</script>

