<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-4 mb-2">
                <device-combo-signals :installation_id="current_installation.id" @add="setSignal"/>
            </div>

            <div class="col-12 col-sm-4 mb-2">
                <label for="">{{ this.$t('select_period') }}</label>
                <vue-datepicker-ui
                    range
                    circle
                    show-clear-button
                    lang="es"
                    :placeholder="this.$t('select_period')"
                    @input='setPeriod'
                    :date-format="this.formatDate"
                    text-format="short"
                />
            </div>
            <div class="col-12 col-sm-4 mb-2">
                <label for="">{{ this.$t('analytic_metric') }}</label>
                <v-select :options="avg_type_options" v-model="avg_type" @input="setAvgType"/>
            </div>
        </div>

        <hr>
        <signal-his-chart
            height="350px"
            v-if="series[0].data.length > 0"
            id="HistoryChartBars"
            :series="series"
        />
    </div>
</template>
<script>
import DeviceComboSignals from '/../Modules/DevicesBundle/Resources/assets/components/devices/DeviceComboSignals'
import ChartApex from '/../resources/js/components/Charts/ChartApex'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import {mapGetters} from "vuex";
import SignalHisChart from "./SignalHisChart.vue";

export default {
    name: 'AnalyticsHistory',
    components: {
        DeviceComboSignals,
        ChartApex,
        vSelect,
        VueDatepickerUi,
        SignalHisChart
    },
    computed: {
        ...mapGetters('analytics', ['current_installation']),
        avg_type_options: function () {
            return [
                {
                    value: 'hour',
                    label: this.$t('by_hour')
                },
                {
                    value: 'day',
                    label: this.$t('by_day')
                },
                // {
                //     value: 'minute',
                //     label: this.$t('by_minute')
                // }
            ]
        }
    },
    created() {
    },
    data() {
        return {
            filter: {
                signals: [],
                avg_type_id: 'hour',
                period: [],
            },
            avg_type: {
                value: 'hour',
                label: this.$t('by_hour')
            },
            formatDate: {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            },

            series: [{
                name: 'series-1',
                data: []
            }]

        }
    },
    methods: {
        setSignal(payload) {
            this.filter.signals = payload
            this.getData()
        },
        setAvgType() {
            this.filter.avg_type_id = this.avg_type ? this.avg_type.value : null
            this.getData()
        },

        setPeriod(payload) {
            if (!payload.includes(null)) {
                this.filter.period = [
                    new Date(payload[0]).toString().slice(0, 15),
                    new Date(payload[1]).toString().slice(0, 15)
                ]
            } else {
                this.filter.period = []
            }
            this.getData()
        },

        getData() {
            if (this.filter.avg_type_id && this.filter.signals.length > 0 && this.filter.period.length > 0) {
                const filter = {...this.filter}
                const signals = `${filter.signals.map((n, index) => `signals[${index}]=${n}`).join('&')}`;
                delete filter.signals;
                let query = this.$helpers.setUrlParameters(filter)
                query += `&${signals}`;
                axios.get(`/api-admin/signals/history` + query).then(res => {
                    this.series = res.series
                })
            }
        },
    }
}
</script>
