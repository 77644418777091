<template>
    <card-template class="w-100">
        <div class="row ">
            <div class="col-12 col-sm-4 mb-3">
                <img class="img-fluid avatar mb-3" :src="image_path">
                <div class="mb-3">
                    <div class="custom-file">
                        <input type="file" @change="setFile" class="custom-file-input" id="file">
                        <label class="custom-file-label" for="file">{{ fileName }}</label>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="name" class="required">{{ this.$devices_locale.columns.name }}</label>
                            <input :readonly="this.show" type="text" v-model="formData.name"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="name">{{ this.$devices_locale.columns.code }}</label>
                            <input :readonly="this.show" type="text" v-model="formData.code"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="mb-3">
                            <label for="name">UUID</label>
                            <input type="text" v-model="formData.datalogger_uuid"
                                   class="form-control">
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3 ">
                        <device-type-combo v-if="!this.show" :current="deviceType" @add="setDeviceType"
                                           :required="'required'"
                                           :label="this.$devices_locale.columns.type"></device-type-combo>
                        <span v-else>{{ this.$devices_locale.columns.type}}: {{this.deviceType?this.deviceType.name:' - '}}</span>
                    </div>
                    <div class="col-12 col-md-4 mb-3 ">
                        <zone-combo v-if="!this.show" :current="zone" @add="setZone" :required="'required'"
                                    :label="this.$devices_locale.columns.zone"></zone-combo>
                        <span
                            v-else>{{ this.$devices_locale.columns.zone}}: {{this.zone?this.zone.name:' - '}}</span>

                    </div>
                    <div class="col-12 col-md-4 mb-3 ">
                        <device-combo v-if="!this.show" :current="parent_device" @add="setDevice"
                                      :label="this.$devices_locale.columns.device_depend"></device-combo>
                        <span v-else>{{ this.$devices_locale.columns.device}}: {{this.parent_device?this.parent_device.name:' - '}}</span>

                    </div>
                    <div class="col-12 col-md-4 mb-3 " v-if="this.device!=null">
                        <label for="name">{{ this.$devices_locale.columns.last_lifebit }}</label>
                        <input v-if="!this.show" type="text" readonly="readonly" v-model="this.device.last_lifebit"
                               id="signal_code"
                               class="form-control">
                        <span v-else class="w-100">{{this.device.last_lifebit}}</span>

                    </div>

                    <div class="col-12 ">
                        <label for="descriptionDevice" class="">{{
                                this.$devices_locale.columns.description
                            }}</label>
                        <textarea style="height: 200px;" :readonly="this.show" id="descriptionDevice"
                                  class="form-control"
                                  v-model="formData.description"></textarea>
                    </div>
                </div>

            </div>
<!--            <div class="col-4">-->
<!--                <map-device v-if="this.zone"-->
<!--                            @markerChanged="positionDeviceChange"-->
<!--                            :device_id="this.formData.id"-->
<!--                            position_by="latLng"-->
<!--                            :show="this.show"-->
<!--														:zone="zone"-->
<!--                />-->
<!--                <span v-else><i-->
<!--                    class="fa fa-info-circle mr-2"></i> Para ver el mapa, añada el dispositivo a una zona.</span>-->
<!--            </div>-->
        </div>

        <hr class="my-3">

        <button @click="save" class="btn btn-primary">{{ this.$devices_locale.buttons.save }}</button>
    </card-template>
</template>

<script>
import DeviceTypeCombo from './DeviceTypeCombo'
import DeviceCombo from './DeviceCombo'
import ZoneCombo from './../zones/ZoneCombo'
// import MapDevice from './MapDevice'

export default {
    name: "DeviceForm",
    components: {
        DeviceTypeCombo,
        DeviceCombo,
        ZoneCombo,
        // MapDevice,

    },
    props: {
        device: {required: false},
        show: {default: false},
    },
    created() {
        if (this.device) {
            this.formData._method = 'put'
            this.editPath = '/' + this.device.id
            this.getItem()
            !this.device.lat?this.setDefaultLatLng():''

        }
        this.fileName = this.$t_devices.choose_file
    },
    computed: {
        getTypePos: function () {
            if (!this.formData.lat) {
                return 'latLng'
            }
            return 'checkpoints'
        }
    },
    data() {
        return {
            fileName: '',
            image_path: '/images/no-image.jpg',
            editPath: '',
            deviceType: null,
            parent_device: null,
            zone: null,
            device_signal_time: null,
            formData: {
                id: null,
                name: '',
                code: '',
                description: '',
                device: '',
                zone: '',
                unit: '',
                device_type: '',
                frequency: 1,
                diary_config: [],
                datetime_config: '',
                outputs_config: '',
                change_date: '',
                irrigation_table: '',
                is_controller: 0,
                lat: 0,
                lng: 0,
                datalogger_uuid: null,
                _method: 'post'
            },
        }
    },
    methods: {
        setFile(e) {
            const file = e.target.files[0]
            this.fileName = file.name
            this.formData.image = file
        },
        positionDeviceChange(position) {
            this.formData.lat = position.lat
            this.formData.lng = position.lng
        },
        getConfigurationDevice() {
            this.formData.datetime_config = this.$refs.deviceConfiguration.current_device_date
            this.formData.change_date = this.$refs.deviceConfiguration.change_date
            this.formData.outputs_config = JSON.stringify(this.$refs.deviceConfiguration.$refs.changeOutputDevice.outputs)
        },
        setDefaultLatLng() {
            this.formData.lat = this.device.zone.lat;
            this.formData.lng = this.device.zone.lng;
            this.setZone(this.device.zone)
        },
        save() {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post(`/api-admin/devices${this.editPath}`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/devices/${response.id}/edit`
                }, 1000)
            }).catch(error => {
                setTimeout(() => {
                    // window.location = `/devices/${this.device.id}/edit`
                }, 1000)
            })
        },
        getItem() {
            this.formData.name = this.device.name
            this.formData.code = this.device.code
            this.formData.datalogger_uuid = this.device.datalogger_uuid
            this.formData.id = this.device.id
            this.formData.description = this.device.description
            this.formData.device_type = this.device.device_type_id
            this.formData.zone = this.device.zone_id
            this.formData.device = this.device.device_id
            this.formData.diary_config = this.device.diary_config
            this.formData.is_controller = this.device.is_controller
            this.formData.outputs_config = this.device.outputs_config
            this.formData.irrigation_table = this.device.irrigation_table
            this.formData.lat = this.device.lat
            this.formData.lng = this.device.lng
            this.device.frequency != null ? this.formData.frequency = this.device.frequency : this.formData.frequency
            this.parent_device = this.device.device
            this.deviceType = this.device.device_type
            this.zone = this.device.zone
            this.device_signal_time = this.getDeviceHour(this.device.last_signal_values)
        },
        setDevice(device) {
            this.formData.device = device.id??null
        },
        setDeviceType(deviceType) {
            this.deviceType = deviceType;
            this.formData.device_type = deviceType.id
            this.checkIfDeviceController(deviceType)
        },
        checkIfDeviceController(deviceType) {
            this.formData.is_controller = 0
            if (deviceType.code == 'controller') {
                this.formData.is_controller = 1
            }
        },
        setFrequency(frequency) {
            this.formData.frequency = frequency
        },

        setIrrigationTable(irrigation_table) {
            this.formData.irrigation_table = JSON.stringify(irrigation_table)
        },
        setDiary(diary) {
            this.formData.diary_config = JSON.stringify(diary)
        },
        setZone(zone) {
            // console.log("zone")
            // console.log(zone)
						if(zone && zone.id){
								this.formData.zone = zone.id
								axios.get(`/api-admin/zones/map/zone/${zone.id}`).then(res => {
									// console.log("res.data")
                                    // console.log(res.data)
                                    this.zone = res.data
								})
						}else{
								this.formData.zone = null
								this.zone = {}
						}

        },
        getDeviceHour(signals) {
            if(signals){
                let signalsJson = JSON.parse(signals)
                let hour, minute, seconds;
                Object.keys(signalsJson).forEach((index) => {
                    index== 'hour' ? hour = signalsJson[index] : ''
                    index== 'minute' ? minute = signalsJson[index] : ''
                    index == 'second' ? seconds = signalsJson[index] : ''
                })
                return `${hour}:${minute}:${seconds}`
            }
        }

    }
}
</script>

<style scoped>

</style>
