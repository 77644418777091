import {setServiceCurrentConfig} from "./mutations";

import {setTotalUnread} from "./mutations";

export const getUsersSelector = (context) => {
    axios.get('/api-admin/notifications/users/selector').then(response => {
        context.commit('setUsersSelector', response.data)
    })
}
export const getUserConfig = async (context, id) => {
    const userNotifId = id
    await axios.get(`/api-admin/notifications/users/${userNotifId}`).then(response => {
        context.commit('setUserEditData', response.data)
    })
}
export const save = async (context, data) => {
    axios.post('/api-admin/notifications/users', data).then(res => {
        window.helpers.toastSuccess()
        setTimeout(() => {
            window.location = `/notifications/users/`
        }, 1000)
    })
}
export const getUserNotifications = async (context) => {
    await axios.get('/api-admin/notifications/users').then(response => {
        context.commit('setUsersNotifications', response.data)
    })
}
export const deleteUserConfig = async (context, id) => {
    axios.delete(`/api-admin/notifications/users/${id}`).then(response => {
        getUserNotifications(context)
    })
}
export const saveEnableUserNotifications = async (context, {id, enabled}) => {
    axios.post(`/api-admin/notifications/users/${id}/enable/${enabled}`)
        .then(response => {
            getUserNotifications(context)
        })
}
export const getModuleConfigAction = (context) => {
    axios.get('/api-admin/notifications/config').then(response => {
        context.commit('setModuleConfig', response.data)
    })
}
export const saveModuleConfig = (context, payload) => {
    axios.post('/api-admin/notifications/config', payload).then(response => {
        context.commit('setModuleConfig', response.data)
        window.helpers.toastSuccess();
    })
}

export const getStatusChannelsAction = (context) => {
    axios.get('/api-admin/notifications/channels/status', {noLoader: true}).then(response => {
        context.commit('setStatusChannels', response)
    })
}
export const getUnreadNotifications = (context) => {
    axios.get('/api-admin/notifications/unread', {noLoader: true}).then(response => {
        context.commit('setUnreadNotifications', response.data)
        context.commit('setTotalUnreadCount', response.meta ? response.meta.total : 0)
    })
}


export const getAllNotifications = async (context) => {
    await axios.get(`/api-admin/notifications/`, {noLoader: true})
        .then(response => {
            context.commit('setNotifications', response.data)
        })
}
export const getNotificationsByUser = async (context) => {
    await axios.get(`/api-admin/notifications/user`, {noLoader: true})
        .then(response => {
            context.commit('setNotifications', response.data)
        })
}
export const getNotificationById = async (context, id) => {
    await axios.get(`/api-admin/notifications/user/${id}`)
        .then(response => {
            context.commit('setNotifications', response.data)
        })
}

export const deleteNotification = (context, id) => {
    axios.delete(`/api-admin/notifications/${id}`)
        .then(response => {
            window.toast.success(window.helpers.t('toast.delete_success'))
            context.dispatch('getAllNotifications')
        })
}
export const deleteNotificationUser = (context, id) => {
    axios.delete(`/api-admin/notifications/user/${id}`)
        .then(response => {
            window.toast.success(window.helpers.t('toast.delete_success'))
            context.dispatch('getNotificationsByUser')
        })
}
export const deleteNotificationsSelected = (context, ids) => {
    axios.delete(`/api-admin/notifications/user/selected/${ids}`)
        .then(response => {
            window.toast.success(window.helpers.t('toast.delete_success'))
            context.dispatch('getNotificationsByUser')
        })
}
export const setReadNotification = (context, id) => {
    return axios.post(`/api-admin/notifications/${id}/setread`, {}, {noLoader: true})
        .then(response => {
            setTimeout(() => {
                context.commit('setReadNotification', id)
                context.dispatch('getUnreadNotifications')
            }, 300)
            return response
        })
}
export const readSelectedNotifications = (context, ids) => {
    return axios.post(`/api-admin/notifications/readSelectedNotifications`, {ids: ids}, {noLoader: true})
        .then(response => {
            setTimeout(() => {
                context.dispatch('getUnreadNotifications')
                context.dispatch('getNotificationsByUser')
            }, 300)
            return response
        })
}

/**
 * CHANNELS
 */
export const setChannelsListAction = (context) => {
    axios.get('/api-admin/notifications/channels').then(response => {
        context.commit('setChannelsList', response.data)
    })
}

export const saveChannelConfig = (context, key_channel) => {
    const config = context.state.channels.current_config
    axios.post('/api-admin/notifications/channels', {
        key_channel,
        config
    }).then(response => {
        window.helpers.toastSuccess()
    })
}


export const getChannelStructureAction = (context) => {
    const key = context.state.channels.current_channel_key
    axios.get(`/api-admin/notifications/channels/structure/${key}`).then(response => {
        context.commit('setChannelStructure', response)
    })
}

export const getCurrentChannelAction = (context) => {
    const key = context.state.channels.current_channel_key
    return axios.get(`/api-admin/notifications/channels/${key}`).then(response => {
        context.commit('setCurrentChannel', response.data)
        return true
    })
}

export const toggleChannelStatusAction = (context) => {
    const key = context.state.channels.current_channel_key
    axios.put('/api-admin/notifications/channels/toggle-status', {key}).then(response => {
        context.commit('setChannelsList', response.data)
        getCurrentChannelAction(context)
    })
}

export const upsetCurrentProvider = (context) => {
    if (context.getters.isProviderChannel) {
        const current_channel_config = context.state.channels.current_channel.config
        const key = Object.keys(current_channel_config)[0]
        context.commit('setCurrentProviderService', key)
    }
}

export const upsetCurrentService = (context) => {
    if (context.getters.isServiceChannel) {
        context.commit('setServiceCurrentConfig')
    }
}

