<template>
    <div class="notifications-bell">
        <a class="nav-link dropdown-toggle"  :class="{ 'vibrating': getUnreadNotifications.length > 0 }" href="#" id="comboNotifications" role="button"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-bell" ></i>
            <span v-if="getTotalUnreadCount > 0"  class="badge badge-primary">{{ getTotalUnreadCount }}</span>
        </a>
        <div  class="dropdown-menu dropdown-menu-right "
             aria-labelledby="comboNotifications">
            <div class="bg bg-primary p-2">
                <span>{{$t_notifications('unread_notifications')}}</span>
            </div>
            <div v-if="getTotalUnreadCount > 0" class="w-100" v-for="notification in getUnreadNotifications">
                <div class="border p-3 dropdown-item" @click="stopPropagation">
                    <a  class="text-decoration-none py-0"   :href="`/notifications/user/${notification.notification_id}`">
                        <i class="fa fa-warning text-warning mr-2"></i>{{notification.notification.subject}}
                    </a>
                    <small class="float-right text-right w-100 mx-1" style="font-size:0.6rem">{{notification.date}}</small>
                    <notifications-change-status :notification="notification.notification" ></notifications-change-status>
                </div>
            </div>
            <div v-if="getTotalUnreadCount == 0" class="w-100">
                <div class="border p-3 dropdown-item" @click="stopPropagation">
                    <span class="text-center">{{$t_notifications('empty_notification_unread')}}</span>
                </div>
            </div>
<!--            <div class="dropdown-divider"></div>-->
            <div class="px-2 mt-1 text-right">
                <a href="/notifications/user"><small>{{$t_notifications('show_all')}}</small></a>
            </div>
        </div>

    </div>
</template>
<script>

import {mapGetters} from "vuex";
import NotificationsChangeStatus from "./NotificationsChangeStatus.vue";

export default {
    name: 'NotificationsBell',
    components: {NotificationsChangeStatus},
    created() {
        this.getNotifications()

    },
    computed: {
        ...mapGetters('notifications',['getUnreadNotifications','getTotalUnreadCount'])

    },

    data(){
        return {
        }
    },
    methods: {
        getNotifications(){
            this.$store.dispatch('notifications/getUnreadNotifications')
        }, stopPropagation(event) {
            event.stopPropagation();
        }

    }
}

</script>
<style lang="scss">
.notifications-bell{
    .dropdown-menu{
        min-width:400px;

    }
}
.notifications-bell{
    @keyframes vibrating {
        0% { transform: translateX(0); }
        25% { transform: translateX(-5px) rotate(-5deg); }
        50% { transform: translateX(5px) rotate(5deg); }
        75% { transform: translateX(-5px) rotate(-5deg); }
        100% { transform: translateX(0); }
    }

    .vibrating {
        animation: vibrating 0.4s;
        animation-iteration-count: 2;
    }

}

</style>
