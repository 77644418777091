import Swal from 'sweetalert2';
import 'js-loading-overlay';
import locale from './locale';

const messages = locale.getMessages();

const helpers = {
	t(key) {
		const sessionLocale = window.lang;
		let translation = messages[sessionLocale];
		const keys = key.split('.');
		keys.map((k) => {
			translation = translation[k];
		});
		return translation;
	},

	showLoading() {
		JsLoadingOverlay.show({spinnerIcon: 'ball-clip-rotate-multiple'});
	},
	hideLoading() {
		JsLoadingOverlay.hide();
	},
	showError(message) {
		Swal.fire({
			title: helpers.t('modals.error.title'),
			html: message,
			icon: 'error',
			showCancelButton: false,
			// confirmButtonColor: i18n.t('modals.error.title'),
			confirmButtonText: helpers.t('buttons.success')
		});
	},

	showErrorValidation(errors) {
		let message = '';
		Object.keys(errors).map((key) => {
			message += `<p>${errors[key]}</p>`;
		});
		Swal.fire({
			title: helpers.t('modals.error.title'),
			html: `<div class="col-xs-12 col-sm-8 offset-sm-2 text-left">${message}</div>`,
			icon: 'error',
			showCancelButton: false,
			// confirmButtonColor: i18n.t('modals.error.title'),
			confirmButtonText: helpers.t('buttons.success')
		});
	},

	toastSuccess() {
		toast.success(helpers.t('toast.create_success'));
	},

	toastDeleteSuccess() {
		toast.success(helpers.t('toast.delete_success'));
	},

	hasPermission(permission) {
		return window.userAdmin || window.userPermissions.includes(permission);
	},

	setUrlParameters(object) {
		const keys = Object.keys(object);
		let glue = '';
		let string = '?';
		keys.map(key => {
			string += `${glue}${key}=${object[key]}`;
			glue = '&';
		});
		return string;
	},

	showWarning(title,html=false,callback=null){
		Swal.fire({
			title,
			html,
			icon: 'warning',
			showCancelButton: false,
			confirmButtonColor: '#a23cb6',
			confirmButtonText: 'Aceptar',
			focusConfirm: true
		}).then(({isConfirmed}) => {
			if (isConfirmed && callback){
				callback();
			}
		});
	},

	showConfirm(title,html=false){
		Swal.fire({
			title,
			html,
			type: 'question',
			showCancelButton: false,
			confirmButtonColor: '#d33',
			confirmButtonText: 'Aceptar',
			focusConfirm: true
		});
	},
	setQueryParams(parameters){
		let next = '?';
		let string = '';
		const keys = Object.keys(parameters);
		keys.map(key => {
			string += `${next}${key}=${parameters[key]}`;
			next = '&';
		});

		return string;
	}

};

window.helpers = helpers;

export default helpers;
