<template>
    <card-template class="w-100">
        <template #titleCard>
            {{ $t('ev_state') }}
            <div class="float-right">
                <span @click="getData" class="cursor-pointer">
                    <i class="fa fa-refresh"></i>
                </span>
            </div>
        </template>
        <div v-if="!has_ev">
            <span v-html="$t('empty_ev', {device: device.name})"></span>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-12 input-group mb-3">
                    <input type="text" class="form-control" :placeholder="$t('ev_seconds')"
                           :aria-label="$t('ev_seconds')"
                           aria-describedby="basic-addon2"
                           v-model="seconds"
                    >
                    <div class="input-group-append">
                        <button @click="startEv" class="btn btn-outline-success" type="button">
                            {{ $t('ev_start', {time: seconds}) }}
                            <i class="fa fa-play ml-2"></i>
                        </button>
                    </div>


                    <button @click="stopEv" class="btn btn-outline-danger ml-3" type="button">
                        {{ $t('ev_stop') }}
                        <i class="fa fa-square ml-2"></i>
                    </button>
                </div>
                <div class="col-12 mb-2">
            <span>
                <small><i :class="`mr-2 fa fa-circle ${ev_enabled ? 'text-success' : 'text-danger'}`"></i></small>
                <span v-if="ev_enabled">{{ $t('ev_enabled') }}</span>
                <span v-else>{{ $t('ev_disabled') }}</span>
            </span>
                </div>
            </div>

            <hr>

            <EvHistory/>
        </div>


    </card-template>
</template>
<script>
import {mapGetters} from 'vuex'
import EvHistory from "./EvHistory.vue";

export default {
    name: 'DeviceActions',
    components: {EvHistory},
    props: {
        device: {
            required: true
        }
    },
    computed: {
        ...mapGetters('devices', ['ev_enabled', 'has_ev'])
    },
    created() {
        this.$store.commit('devices/resetData')
        this.$store.commit('devices/setCurrentDevice', this.device)
        this.getData()
    },
    data() {
        return {
            seconds: 3600
        }
    },
    methods: {
        getData() {
            if (this.has_ev) {
                this.$store.dispatch('devices/getEvData')
            }
        },
        startEv() {
            if (this.has_ev) {
                this.$store.dispatch('devices/startEv', this.seconds)
            }
        },
        stopEv() {
            if (this.has_ev) {
                this.$store.dispatch('devices/stopEv')
            }
        }
    }
}
</script>
