import {setEvHistory} from "./mutations";

export const getEvData = (context) => {
    const device = context.state.current_device
    axios.get(`/api-admin/devices/${device.id}/ev-data`).then(response => {
        const {device_ev_open, ev_pin_status, history} = response.data
        context.commit('setEvPinStatus',ev_pin_status)
        context.commit('setEvDeviceStatus',device_ev_open)
        context.commit('setEvHistory',history)

    })
}

export const startEv = (context,timeout) => {
    const device = context.state.current_device
    axios.post(`/api-admin/devices/${device.id}/ev-start`,{timeout}).then(response => {
          getEvData(context)
    })
}

export const stopEv = (context) => {
    const device = context.state.current_device
    axios.post(`/api-admin/devices/${device.id}/ev-stop`).then(response => {
        getEvData(context)
    })
}
