<template>
	<div>
		<card-template>
			<template slot="titleCard">
				<i class="fa fa-filter fa-lg"></i> {{ this.$t('Filter') }}
			</template>
			<div class="row">
				<div class="col-12 col-md-3">
					<label for="">{{ this.$t_history('DateRange') }}</label>
					<Datepicker
						v-model="filterDateRange"
						range
						circle
						show-clear-button
						:lang="this.lang"
						:date-format="this.formatDate"
						:placeholder="this.$t_history('DateRange')"
						text-format="short"
					></Datepicker>
				</div>
				<div class="col-12 col-md-3">
					<label for="">{{ this.$t_history('EventType') }}</label>
					<v-select
						v-model="selectedType"
						:options="typeOptions"
						:placeholder="this.$t_history('EventType')"
						class="mb-2"
						multiple
					></v-select>
				</div>
				<div class="col-12 col-md-5">
					<download-csv
						v-if="historyEvents.length > 0"
						:header="true"
						:data="filteredHistoryEvents"
						class="btn btn-primary btn-md my-2 mt-4 float-right"
					>
						<i class="fa fa-file-excel"></i>
						{{ this.$t('buttons.save') }}
					</download-csv>
				</div>
			</div>
		</card-template>

		<card-template :show_header="false">
			<inno-core-datatable
				:columns-data="columns"
				:row-items="filteredHistoryEvents"
				:id-table="idTable"
				:enable-create-btn="false"
			></inno-core-datatable>
		</card-template>
	</div>
</template>

<script>
import JsonCSV from 'vue-json-csv';
import moment from 'moment';
import VueDatepickerUi from 'vue-datepicker-ui';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import vSelect from 'vue-select';

export default {
	name: 'HistoryEventsList',
	components: {
		vSelect,
		downloadCsv: JsonCSV,
		Datepicker: VueDatepickerUi
	},
	props: {
		lang: {
			required: true
		}
	},
	mounted() {
		this.fetchHistoryEvents();
		this.fetchTypeOptions();
	},
	computed: {
		filteredHistoryEvents() {
			let filteredEvents = this.historyEvents;

			// Filter by date range
			if (this.filterDateRange && this.filterDateRange.length === 2) {
				const startDate = this.filterDateRange[0];
				const endDate = this.filterDateRange[1];

				if (startDate && endDate) {
					filteredEvents = filteredEvents.filter((event) => {
						const eventDate = moment(event.timestamp);
						return eventDate >= startDate && eventDate <= endDate;
					});
				}
			}

			// Filter by type
			if (this.selectedType && this.selectedType.length > 0) {
				filteredEvents = filteredEvents.filter((event) => {
					return this.selectedType.some(
						(selected) => selected.value === event.type
					);
				});
			}

			return filteredEvents;
		}
	},
	data() {
		return {
			idTable: 'historyeventsTable',
			historyEvents: [],
			columns: [
				{
					field: 'timestamp',
					label: this.$t_history('Timestamp')
				},
				{
					field: 'type',
					label: this.$t_history('EventType')
				},
				{
					field: 'event_description',
					label: this.$t_history('EventDescription')
				}
			],
			filterDateRange: [null, null],
			current: [null, null],
			formatDate: {
				month: '2-digit',
				day: '2-digit',
				year: 'numeric'
			},
			selectedType: null,
			typeOptions: []
		};
	},
	methods: {
		fetchHistoryEvents() {
			axios.get('/api-admin/history/history-events').then((response) => {
				this.historyEvents = response.map((event) => {
					return {
						...event,
						timestamp: moment(event.timestamp).format(
							'YYYY-MM-DD HH:mm:ss'
						)
					};
				});
			});
		},

		fetchTypeOptions() {
			axios
				.get('/api-admin/history/history-events/types')
				.then((response) => {
					this.typeOptions = response.map((option) => ({
						label: option.type,
						value: option.type
					}));
				});
		}
	}
};
</script>
<style scoped>
.vs__search {
	height: 50px;
}
</style>
