<template>
    <div>
        <div class="row mb-2">
            <div class="col-12 mb-2">
                <div class="row">
                    <div class="col-12 col-sm-2">
                        <installations-combo :current="current_installation" :show_label="true" @add="setInstallation"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="current_installation" class='tab-content' id='pills-tabContent'>
            <card-template :show_header="false" class="tab-pane show fade active" id="tab-history" role="tabpanel"
                           ria-labelledby='pills-his'>
                <div class="row">
                    <History class="col-12"/>
                </div>

            </card-template>
        </div>
    </div>
</template>
<script>
import History from './AnalyticsHistory'
import SignalRealTime from './SignalRealTime'
import {mapGetters} from 'vuex'
import InstallationsCombo from '/../Modules/Installations/Resources/assets/components/InstallationsCombo.vue'

export default {
    name: 'Analytics',
    props: {
        installation_id: {}
    },
    components: {
        History,
        SignalRealTime,
        InstallationsCombo
    },
    created() {

    },
    computed: {
        ...mapGetters('analytics', ['current_installation']),
        status: function () {
            const status_client = this.getClient.status
            let status_color = 'secondary'
            switch (status_client) {
                case 'connected':
                    status_color = 'success'
                    break

                case 'offline':
                    status_color = 'danger'
                    break
                case 'error':
                    status_color = 'danger'
                    break

            }
            return {
                text: status_client,
                color: status_color
            }
        },
    },
    data() {
        return {

        }
    },
    methods: {
        setInstallation(payload) {
            this.$store.commit('analytics/setCurrentInstallation', payload)
        }
    }
}
</script>
