<template>
    <div>
        <notifications-general :management="true"></notifications-general>
    </div>
</template>
<script>

import {mapGetters} from "vuex";
import NotificationsGeneral from "../NotificationsGeneral.vue";

export default{
    name:'NotificationsTable',
    components: {NotificationsGeneral},
    created() {
        this.getAllNotifications()
    },
    computed: {
    },
    data() {
        return {

        }
    },
    methods:{
         getAllNotifications() {
             this.$store.dispatch('notifications/getAllNotifications')
        },
    }
}

</script>
