<template>
    <div>
        <apex-chart :height="height" :options="chart_options" :series="series"/>
    </div>
</template>
<script>
import ApexChart from 'vue-apexcharts'

export default {
    name: 'ChartApex',
    props: {
        title: {
            type: String
        },
        id: {
            required: true
        },
        yaxis_scale: {},
        height: {},
        chart_type: {
            type: String,
            default: 'area' //area, line, bar, pie
        },
        xaxis_type: {
            type: String,
            default: 'category' //category, datetime, numeric
        },
        series: {
            type: Array,
            required: true,

        },
        show_guide: {
            type: Boolean,
            default: false
        },
        guide_value: {
            type: Number,
        },
        guide_label: {
            type: String,
            default: ''
        }
    },
    components: {
        ApexChart
    },
    created() {
        if (this.yaxis_scale && this.yaxis_scale.max) {
            this.chart_options.yaxis = this.yaxis_scale
        }

        if (this.show_guide) {
            this.chart_options.annotations = {
                yaxis: [
                    {
                        y: this.guide_value,
                        borderColor: '#e3003d',
                        label: {
                            borderColor: '#e3003d',
                            style: {
                                color: '#fff',
                                background: '#e3003d'
                            },
                            text: this.guide_label
                        }
                    }
                ]
            }
        }
    },
    data() {
        return {
            chart_options: {
                title: {
                    text: this.title,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                chart: {
                    id: this.id,
                    type: this.chart_type,
                    height: '350px',
                    animations:{
                        enabled: true
                    },
                    zoom: {
                        autoScaleYaxis: true
                    }
                },
                // dataLabels: {
                // 		enabled: false
                // },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                xaxis: {
                    type: this.xaxis_type,
                    tickAmount: 6,
                    labels: {
                        format: 'dd/MM HH:mm',
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                    }
                },
                yaxis: {},
                // tooltip: {
                // 		x: {
                // 				format: 'dd MMM yyyy HH:mm'
                // 		}
                // },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },

                stroke: {
                    show:true,
                    curve: 'straight'
                    // curve: ['smooth','straight','stepline']
                },

                plotOptions: {
                    bar: {
                        dataLabels: {
                            offsetY: 0,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 'bold',
                                colors: ['#000']
                            },
                        }
                    }
                }
            }
        }
    },
}
</script>
