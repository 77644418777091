<template>
    <card-template>
        <template slot="titleCard">{{ this.$alarms_locale.alarms_list }}</template>
        <div class="table-responsive">
            <alarms-table
                :enableCreateBtn="false"
                :idTable="idTable"
                :columnsData="columns"
                :customFilters="customFilters"
                :rowItems="items"
                :customFields="customFields"
                :btnConfig="false"
                @edit="editItem"
                @delete="deleteItem"
                @new="newItem"
            >
            </alarms-table>
        </div>
        <modal-confirm-delete @confirmed="removeItem"/>

    </card-template>
</template>
<script>

export default {
    name: 'AlarmsList',
    created() {
        this.getAlarms()
    },
    data() {
        return {
            idTable: 'alarmsTable',
            items: [],
            customFilters: [
                'date_range','installation'
            ],
            customFields: [

            ],
            columns: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "installation",
                    "label": this.$t('Installation')
                },
                {
                    "field": "item_code",
                    "label": this.$alarms_locale.table_columns.code
                }, {
                    "field": "item_value",
                    "label": this.$alarms_locale.table_columns.item_value
                },
                {
                    "field": "type",
                    "label": this.$alarms_locale.table_columns.type
                },
                {
                    "field": "start_date",
                    "label": this.$alarms_locale.table_columns.start_date
                },
                {
                    "field": "end_date",
                    "label": this.$alarms_locale.table_columns.end_date
                },
                {
                    "field": "elapsed_time",
                    "label": this.$alarms_locale.table_columns.elapsed_time
                },{
                    "field": "state",
                    "label": this.$alarms_locale.table_columns.state
                },
                {
                    "field": "options",
                    "label": this.$alarms_locale.table_columns.options
                }
            ]
        }
    },
    computed: {},
    methods: {
        getAlarms() {
            axios.get('/api-admin/alarms/getAlarms').then(response => {
                const rows = response.data
                this.items = rows
                console.log(response)
            })
        },
        editItem(item) {
            window.open(`/alarms/${item.id}/edit`,'_blank')
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDelete', {
                text: `${this.$readings_locale.modals.delete_task} ${item.name} `,
                parameters: item
            })
        },
        removeItem(item) {
            axios.delete(`/api-admin/alarms/${item.id}`).then(response => {
                const rows = response.data
                this.items = rows
                this.$toast.success(this.$t('toast.delete_success'))
            })
        },
        newItem() {
            window.location = '/alarms/create'
        },
        setItem(item) {
            window.location = `/alarms/${item.id}/config`
        }
    }

}
</script>

