export const getModuleName = (state) => state.module_name
export const notificationsConfig = (state) => state.config
export const hasNotificationsEnabled = state => state.config.module_enabled

export const getUsersSelector = (state) => {
    return state.users_selector
}
export const getUserNotificationData = (state) => {
    return state.user_notification_data
}
export const getUsersNotificationsData = (state) => {
    return state.users_notifications
}
export const getNotifications = (state) => {
    return state.notifications
}
export const getCurrentNotificationDetail = (state) => {
    return state.current_notification_detail
}
export const getNotificationByUser = (state) => {
    return state.user_notifications
}
export const getUnreadNotifications = (state) => {
    return state.unread_notifications
}
export const getTotalUnreadCount = (state) => {
    return state.total_unread_count
}

/**
 * CHANNELS
 */


export const hasEmailChannelEnabled = state => state.channels.status.email
export const hasTeamsChannelEnabled = state => state.channels.status.microsoft_teams
export const hasWhatsappChannelEnabled = state => state.channels.status.whatsapp
export const hasSMSChannelEnabled = state => state.channels.status.sms

export const channelsList = state => state.channels.list
export const getComboChannels = state => {
    return Object.keys(state.channels.current_channel_structure)
}


export const getProviderStructure = state => {
    const protocol = state.channels.current_protocol
    return state.channels.current_channel_structure[protocol];
}

export const channelCurrentProviderConfig = state => {
    const service = state.channels.current_provider_service
    return state.channels.current_config[service]
}

export const channelCurrentServiceConfig = state => {
    return state.channels.current_config
}

export const currentProviderService = state => state.channels.current_provider_service

export const getCurrentProviderStructure = state => {
    const service = state.channels.current_provider_service
    return state.channels.current_channel_structure[service]
}

export const getCurrentServiceStructure = state => {
    return state.channels.current_channel_structure
}


export const getCurrentChannel = state => state.channels.current_channel

export const currentChannelKey = state => state.channels.current_channel_key

export const isProviderChannel = state => {
    const channel_key = state.channels.current_channel_key
    return state.channels.providers.includes(channel_key)
}

export const isServiceChannel = state => {
    const channel_key = state.channels.current_channel_key
    return state.channels.services.includes(channel_key)
}
