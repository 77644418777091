<template>
		<div>
				<Chart
						:id="signal.id"
						:series="series"
						height="200px"
						chart_type="bar"
						:title="signal.label"
						:yaxis_scale="yaxis_scale"
				/>
		</div>

</template>
<script>
import Chart from '/../resources/js/components/Charts/ChartApex'
import {mapGetters} from 'vuex'

export default {
		name: 'SignalRealTime',
		components: {
				Chart
		},
		props: {
				device:{},
				signal: {
						required: true,
				}
		},
		computed: {
				...mapGetters('mqtt', ['getData']),
				series: function () {
						const data = this.getData[this.signal.code]
						const value = data ? parseFloat(data.value).toFixed(2) : 0
						this.setYScale({
								min: 0,
								max: data ? data.max : null
						})

						return [{
								data: [{x: '', y: value}]
						}]
				},
		},
		data() {
				return {
						yaxis_scale:{}
				}
		},
		methods: {
				setYScale(payload){
						if(!this.yaxis_scale.max){
								this.yaxis_scale = payload
						}
				}
		}
}
</script>