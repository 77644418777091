<template>
    <card-template>
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="mb-3">
                    <label for="name" class="required">{{ this.$signals_locale.columns.name }}</label>
                    <input @input="setCodeSignal()" type="text" v-model="formData.name" id="signal_name"
                           class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="mb-3">
                    <label for="name">{{ this.$signals_locale.columns.code }}</label>
                    <input type="text" readonly="readonly" v-model="formData.code" id="signal_code"
                           class="form-control">
                </div>
            </div>

            <div class="col-12 col-md-4">
                <div class="mb-3">
                    <label for="name">{{ $t_devices('datalogger_code') }}</label>
                    <input type="text" v-model="formData.datalogger_code"
                           class="form-control">
                </div>
            </div>

            <div class="col-12 col-md-2">
                <signal-type-combo :current="signalType" @add="setSignalType" :required="'required'"
                                   :label="this.$signals_locale.columns.type"></signal-type-combo>

            </div>
            <div class="col-12 col-md-2">
                <div class="mb-3">
                    <label for="name">{{ this.$signals_locale.columns.unit }}</label>
                    <input type="text" v-model="formData.unit" id="signal_unit" class="form-control">
                </div>
            </div>
            <div class="col-12 col-md-4 ">
                <device-combo :current="device" @add="setDevice" :required="'required'"
                              :label="this.$signals_locale.columns.device"></device-combo>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mb-3 ">
                <label for="descriptionTask" class="">{{
                        this.$signals_locale.columns.description
                    }}</label>
                <textarea id="descriptionTask" class="form-control"
                          v-model="formData.description"></textarea>
            </div>
        </div>
        <hr>
        <button @click="save" class="btn btn-primary">{{ this.$signals_locale.buttons.save }}</button>
        <button-href v-if="signal" :item_id="this.signal.id" item_type="DevicesBundle" item_model="Signal"></button-href>
    </card-template>
</template>

<script>
import DeviceCombo from './../devices/DeviceCombo'
import SignalTypeCombo from './SignalTypeCombo'
import ButtonHref from './../alarms/ConfigAlarms/ButtonHref'

export default {
    name: "SignalForm",
    components: {
        DeviceCombo,
				SignalTypeCombo,
				ButtonHref
    },
    props: {
        signal: {required: false},
    },
    created() {
        if (this.signal) {
            this.formData._method = 'put'
            this.editPath = '/' + this.signal.id
            this.getItem()
        }
    },
    data() {
        return {
            device: {
                code: ''
            },
            editPath: '',
            signalType: null,
            formData: {
                id: null,
                name: '',
                code: '',
                device: '',
                description: '',
                unit: '',
                signal_type: '',
                _method: 'post',
                datalogger_code: null
            },
        }
    },
    methods: {
        getItem() {
            this.formData.name = this.signal.name
            this.formData.datalogger_code = this.signal.datalogger_code
            this.formData.code = this.signal.code
            this.formData.id = this.signal.id
            this.formData.signal_type = this.signal.signal_type?this.signal.signal_type.id:null
            this.formData.device = this.signal.device.id
            this.formData.unit = this.signal.unit
            this.formData.description = this.signal.description
            this.device = this.signal.device
            this.signalType = this.signal.signal_type
        },
        setDevice: function (device) {
            this.device = device;
            this.formData.device = device.id
            this.setCodeSignal()
        },
        setCodeSignal: function () {
            let codeSignal = `${this.device.code}_${this.formData.name}`
            this.formData.code = codeSignal
        },
        setSignalType: function (signalType) {
            this.signalType = signalType;
            this.formData.signal_type = signalType.id
        },
        save: function () {
            let formData = new FormData()
            Object.keys(this.formData).map(field => {
                formData.append(field, this.formData[field] === null ? '' : this.formData[field])
            })
            axios.post(`/api-admin/signals${this.editPath}`, formData, {headers: {'content-type': 'multipart/form-data'}}).then(response => {
                this.$helpers.showLoading()
                this.$helpers.toastSuccess()
                setTimeout(() => {
                    window.location = `/signals/${response.id}/edit`
                }, 1000)
            })
        }
    }
}
</script>

<style scoped>

</style>
