<template>
    <div class="mb-3">
        <label :class="required">{{ this.$tc('Signal',2) }}</label>
        <tree-select
						@input="add"
						v-model="selected"
						:options="items"
						label="label"
						code="id"
						:disable-branch-nodes="true"
						search-nested
                        :multiple="true"
				></tree-select>
    </div>
</template>
<script>
import TreeSelect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: 'DeviceComboSignals',
    props: {
        'current': {type: Object},
        'multiple': {default: false},
        'required': {default: ''},
				'installation_id' : {required: true}
    },
    components: {
        TreeSelect,
    },
    created() {
        this.setData()
        this.selected = this.current ? {...this.current} : null
    },
    watch: {
        current: function () {
            this.selected = {...this.current}
        },
    },
    data() {
        return {
            items: [],
            selected: {},
        }
    },
    methods: {
        add(payload) {
            this.$emit('add', payload || null)
        },
        setData() {
            axios.get('/api-admin/devices/installation/'+this.installation_id+'/combo-signals').then(response => {
                this.items = response.data
            })
        },
    }
}
</script>
