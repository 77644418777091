<template>
    <card-template :show_header="false" padding="p-0">
        <div class="list-group">
            <button @click="syncDataloggerStructure" type="button" class="list-group-item list-group-item-action">
                {{$t('sync_dlog_struct')}}
            </button>
        </div>
    </card-template>
</template>
<script>
export default{
    name: 'ActionsList',
    methods:{
        syncDataloggerStructure(){
            axios.post('/api-admin/management/actions/sync-datalogger-structure',{}).then(()=>{
                this.$helpers.toastSuccess()
            })
        }
    }
}
</script>
