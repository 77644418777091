<template>
	<div class="card">
		<div
			class="card-header pl-3 pt-3 cursor-pointer bg-primary"
			data-toggle="collapse"
			data-target="#collapseHistory"
			aria-expanded="false"
			aria-controls="collapseHistory"
		>
			<h4 style="margin: 0">
				{{ this.$t_history('History') }}
			</h4>
		</div>
		<div
			id="collapseHistory"
			:class="`collapse border-top card-body ${show ? 'show' : null}`"
		>
			<v-client-table
				ref="HistoryTable"
				:data="items"
				:columns="columns"
				:options="options"
			></v-client-table>
		</div>
	</div>
</template>
<script>
export default {
	name: 'HistoryTable',
	props: {
		items: {
			required: true,
			type: Array
		},
		show: {
			type: Boolean,
			default: false
		}
	},
	created() {
		this.setColumns();
	},
	computed: {
		columnsConfig: function () {
			return [
				{
					field: 'comment',
					label: this.$t_history('Comment')
					// display: 'min_tabletP',
				},
				{
					field: 'field',
					label: this.$t_history('Field')
					// display: 'min_tabletP',
				},
				{
					field: 'old_value',
					label: this.$t_history('old_value')
					// display: 'min_tabletP',
				},
				{
					field: 'new_value',
					label: this.$t_history('new_value')
					// display: 'min_tabletP',
				},
				{
					field: 'date',
					label: this.$t_history('Date')
					// display: 'min_tabletP',
				}
			];
		}
	},
	data() {
		return {
			columns: [],
			options: {
				uniqueKey: 'code',
				resizableColumns: true,
				perPage: 10,
				perPageValues: [5, 10, 50, 100],
				pagination: { chunk: 5 },
				orderBy: {
					column: 'state_id',
					ascending: false
				},
				datepickerOptions: {
					showDropdowns: true,
					autoUpdateInput: true
				},
				sortIcon: {
					base: 'fa float-right',
					is: 'fa-sort',
					up: 'fa-sort-up',
					down: 'fa-sort-down'
				},
				texts: {
					count: `${this.$t(
						'Showing {from} to {to} of {count} records|{count} records|One record'
					)}`,
					first: `${this.$t('First')}`,
					last: `${this.$t('Last')}`,
					filter: `${this.$t('Filter')}:`,
					filterPlaceholder: `${this.$t('Search query')}`,
					limit: `${this.$t('Records')}:`,
					page: `${this.$t('Page')}:`,
					noResults: `${this.$t('No matching records')}`,
					filterBy: `${this.$t('Filter by {column}')}`,
					loading: `${this.$t('Loading')}...`,
					defaultOption: `${this.$t('Select {column}')}`,
					columns: `${this.$t('Columns')}`
				}
			}
		};
	},
	methods: {
		setColumns() {
			const headings = {};
			this.columnsConfig.map((item) => {
				this.columns.push(item.field);
				headings[item.field] = item.label;
				if (item.display) {
					this.options.columnsDisplay[item.field] = item.display;
				}
			});
			this.options.headings = headings;
		}
	}
};
</script>
