<template>
    <div>
        <card-template>
            <template slot="titleCard">{{ this.$t_notifications('management.title') }}</template>
            <div class="table-responsive userNotifTable">
                <v-client-table :ref='idTable' :data='getNotifications' :columns='columns' :options='options'
                                @select="selectRows">

                    <div v-if="!management" slot="afterFilter" class="after__filter">
                        <div class="row justify-content-end">
                            <button @click="markSelected?markSelectedRead():''"
                                    :class="!markSelected?'btn-disable disabled':''" class="btn btn-primary">
                                <i class="fa fa-envelope-open mr-1"></i>{{ $t_notifications('mark_all_read') }}
                            </button>
                            <button @click="markSelected?deleteNotificationsSelected():''"
                                    :class="!markSelected?'btn-disable disabled':''" class="ml-2 btn btn-danger">
                                <i class="fa fa-trash mr-1"></i>{{ $t_notifications('delete_selected') }}
                            </button>
                        </div>
                    </div>
                    <template v-for="(item) in customFields">
                        <div v-html :slot="item.field" v-html="item.content"> {{ item.content }}</div>
                    </template>

                    <div slot='options' slot-scope='{row}' class=" text-center">
                        <button @click='showDetails(row)' class='btn btn-xs btn-info'><i class='fa fa-info-circle'></i>
                        </button>
                        <button v-if="management && (typeof $helpers.hasPermission ==='function') && $helpers.hasPermission('notifications.delete') "
                                @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i>
                        </button>
                        <button v-if="!management && (typeof $helpers.hasPermission ==='function') && $helpers.hasPermission('notifications.delete')"
                                @click='deleteItem(row)' class='btn btn-xs btn-danger'><i class='fa fa-trash'></i>
                        </button>
                    </div>
                    <div slot='read' slot-scope='{row}' class="text-left pl-2">
                        <notifications-change-status :notification="row"></notifications-change-status>
                    </div>
                </v-client-table>
            </div>
        </card-template>
        <modal-confirm-delete-fn/>
        <notification-details-modal :management="management" v-if="showModal" @showModalDetail="showModal"
                                    :showModal="showModalDetail"/>
    </div>
</template>
<script>

import {mapGetters} from "vuex";
import NotificationDetailsModal from "./NotificationDetailsModal.vue";
import NotificationsChangeStatus from "./NotificationsChangeStatus.vue";

export default {
    name: 'NotificationsGeneral',
    components: {NotificationsChangeStatus, NotificationDetailsModal},
    props: {
        management: {default: false}
    },
    created() {
        this.setColumns()
    },
    computed: {
        ...mapGetters('notifications', ['getNotifications']),
    },
    data() {
        return {
            columns: [],
            showModalDetail: false,
            selectAll: false,
            markSelected: false,
            columnsData: [
                {
                    "field": "id",
                    "label": "#",
                    "display": "min_tabletP"
                },
                {
                    "field": "subject",
                    "label": this.$t_notifications('management.columns.subject')
                },
                {
                    "field": "message",
                    "label": this.$t_notifications('management.columns.message')
                },
                {
                    "field": "date",
                    "label": this.$t_notifications('management.columns.date')
                },
                {
                    "field": "read",
                    "label": this.$t_notifications('management.columns.read')
                },
                {
                    "field": "options",
                    "label": this.$t_notifications('users_management.columns.options')
                }
            ],
            options: {
                rowClassCallback: row => {
                    return row.disabled ? 'disabled-item' : null
                },
                resizableColumns: true,
                perPage: 10,
                perPageValues: [5, 10, 50, 100],
                pagination: {chunk: 5},
                datepickerOptions: {
                    showDropdowns: true,
                    autoUpdateInput: true,
                },
                sortIcon: {
                    base: 'fa float-right',
                    is: 'fa-sort',
                    up: 'fa-sort-up',
                    down: 'fa-sort-down',
                },
                selectable: {
                    mode: 'multiple',
                    only: function (row) {
                        return true
                    },
                    selectAllMode: 'all',
                    programmatic: false
                },
                texts: {
                    count: `${this.$t('Showing {from} to {to} of {count} records|{count} records|One record')}`,
                    first: `${this.$t('First')}`,
                    last: `${this.$t('Last')}`,
                    filter: `${this.$t('Filter')}:`,
                    filterPlaceholder: `${this.$t('Search query')}`,
                    limit: `${this.$t('Records')}:`,
                    page: `${this.$t('Page')}:`,
                    noResults: `${this.$t('No matching records')}`,
                    filterBy: `${this.$t('Filter by {column}')}`,
                    loading: `${this.$t('Loading')}...`,
                    defaultOption: `${this.$t('Select {column}')}`,
                    columns: `${this.$t('Columns')}`,
                },
                columnsDisplay: {},
            },
            idTable: 'userNotifTable',
            customFilters: [],
            customFields: [],
        }
    },
    methods: {

        setColumns() {
            const headings = {}
            this.columnsData.map(item => {
                if (item.field == 'read') {
                    if (!this.management) {
                        this.columns.push(item.field)
                        headings[item.field] = item.label
                        if (item.display) {
                            this.options.columnsDisplay[item.field] = item.display
                        }
                    }
                } else {
                    this.columns.push(item.field)
                    headings[item.field] = item.label
                    if (item.display) {
                        this.options.columnsDisplay[item.field] = item.display
                    }
                }

            })
            this.options.headings = headings
        },
        toggleSelectAll() {
            this.data.forEach((item) => {
                item.selected = this.selectAll;
            });
        },
        showModal(state) {
            this.showModalDetail = state
        },
        showDetails(item) {
            this.$store.commit('notifications/currentNotificationDetail', item)
            this.showModalDetail = true
        },
        deleteItem(item) {
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$t_notifications('management.delete_notif')} "${item.subject} "`,
                parameters: item,
                fn: this.removeItem
            })
        },
        removeItem(item) {
            if (this.management) {
                this.$store.dispatch('notifications/deleteNotification', item.id)
            } else {
                this.$store.dispatch('notifications/deleteNotificationUser', item.id)
            }
        },
        deleteNotificationsSelected(){
            this.$bus.$emit('fireModalConfirmDeleteFn', {
                text: `${this.$t_notifications('management.delete_notif')}`,
                parameters: null,
                fn: this.removeSelectedItems
            })
        },
        removeSelectedItems() {
            const notificationsIds = this.$refs.userNotifTable.selectedRows.map(item => item.id)
            this.$store.dispatch('notifications/deleteNotificationsSelected', notificationsIds)

        },
        changeStatus(id) {
            this.$store.dispatch('notifications/setReadNotification', id)

        },
        markSelectedRead() {
            const notificationsIds = this.$refs.userNotifTable.selectedRows.map(item => item.id)
            this.$store.dispatch('notifications/readSelectedNotifications', notificationsIds)
        },
        selectRows(idsSelected) {
            idsSelected.length > 0 ? this.markSelected = true : this.markSelected = false;
        }
    }
}

</script>

<style lang="scss">
.userNotifTable {
    .VueTables__search {
        width: 100%;
        display: flex;
    }

    .VueTables__search-field {
        width: 25%;
        margin-right: 5%;
    }

}

</style>
