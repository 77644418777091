<template>
    <div>
        <notifications-general></notifications-general>
    </div>
</template>
<script>

import NotificationsGeneral from "../NotificationsGeneral.vue";

export default {
    name: 'NotificationsUserTable',
    components: {NotificationsGeneral},
    props: {
        notification_id: {default: null, required: false}
    },
    created() {
        this.getAllNotifications()
    },
    computed: {},
    data() {
        return {}
    },
    methods: {
        getAllNotifications() {
            if (this.notification_id) {
                this.$store.dispatch('notifications/getNotificationById', this.notification_id)
                this.$store.dispatch('notifications/setReadNotification', this.notification_id)
            } else {
                this.$store.dispatch('notifications/getNotificationsByUser')
            }
        },
    }
}

</script>
