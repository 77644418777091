<template>
    <div class="w-100">
        <div id="deviceToolbar" class="mb-2">
            <div class="row">
                <div class="col-sm-4 mb-2">
                    <ul class="nav nav-pills" id="deviceViews" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link  nav-link-info" id="device-table-tab" data-toggle="pill"
                               href="#deviceTable" role="tab" aria-controls="deviceTable" aria-selected="true">
                                <i class="fa fa-list"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active nav-link-info" id="device-grid-tab" data-toggle="pill"
                               href="#deviceGrid"
                               role="tab" aria-controls="deviceGrid" aria-selected="false">
                                <i class="fa fa-th-large"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-8 text-right mb-2">
                    <a :href="'/devices/create'" class="btn btn-success btn-sm"><i class="fa fa-plus"></i>
                        {{ this.$t('buttons.new') }}
                    </a>
                </div>
            </div>
        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" role="tabpanel" id="deviceTable" :show_header="false"
                 padding="p-0">
                <DeviceTable @filterInstallation="filterInstallation" :devices="items"></DeviceTable>
            </div>
            <card-template class="tab-pane show fade active" role="tabpanel" id="deviceGrid" :show_header="false">
                <DeviceGrid :devices="items"></DeviceGrid>
            </card-template>
        </div>
    </div>
</template>

<script>
import DeviceTable from "./device-views/DeviceTable";
import DeviceGrid from "./device-views/DeviceGrid";

export default {
    name: "DeviceList",
    data() {
        return {
            items: [],
            installationFilter:null
        }
    },
    components: {DeviceTable, DeviceGrid},
    created() {
        this.getItems()
    },
    methods: {
        getItems() {
            this.axiosCallGetItems(this.installationFilter)
            // setInterval(() => {
            //    this.axiosCallGetItems(this.installationFilter)
            // },3000)
        },
        filterInstallation(installation){
            this.installationFilter = installation?installation.id:installation
            this.axiosCallGetItems();
        },
        axiosCallGetItems() {
            const query = this.installationFilter?`installation=${this.installationFilter}`:''
            axios.get('/api-admin/devices?'+query,{hideLoader:true}).then(response => {
                const rows = response.data
                this.items = rows
            })
        }
    }
}
</script>

<style scoped>

</style>
